.mt-10 {
  margin-top: 55px;
}

.grid {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 10px 10px;
}

.grid_modal {
  display: grid;
  grid-template-columns: 15% 15% 20% 20% 30%;
  gap: 2px 12px;
}
.css-1uccc91-singleValue {
  color: black !important;
}


@media only screen and (max-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    gap: 12px 30px;
  }
}
.co{
  color: #495057;
}

@media only screen and (max-width: 600px) {
  .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 12px 30px;
  }
}
.c-pointer{
  cursor: pointer;
}
.react-kanban-column{
  min-width: 400px;
}

.reactselect-invalid {
  border: 1px solid #f46a6a;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.reactselect-invalid > .select2-selection__control  {
  border: none;
}

.z-index-0{
  z-index: 0 !important;
}
